





















import { Vue, Component } from "vue-property-decorator";
import InvoicesResource from "@/resources/invoices-resource";
import { NotificationFactory } from "@/utils/notificationFactory";
import { InvoiceStatus } from "@/data/invoice";
@Component
export default class InvoiceSendView extends Vue {
  loading: boolean = false;
  invoice: InvoiceStatus | null = null;
  email = "";

  async created(): Promise<void> {
    await this.loadInvoice();
  }

  async notify(): Promise<void> {
    await this.$validator.validate("form.*").then(async (res: boolean) => {
      if (res) {
        const invoice = this.invoice as InvoiceStatus;
        this.loading = true;
        try {
          await InvoicesResource.notificate(
            invoice.getNotificationRequest(this.email)
          ).then(() => {
            NotificationFactory.success(
              "Ссылка отправлена указанному получателю"
            );
          });
        } finally {
          this.loading = false;
        }
      }
    });
  }

  async loadInvoice(): Promise<void> {
    const code = this.$route.params.code;
    this.loading = true;
    try {
      await InvoicesResource.get(code).then(res => {
        this.invoice = new InvoiceStatus(res);
        if (this.invoice.isPaymentStarted) {
          this.$router.push({
            name: "invoice-success",
            params: { code: code }
          });
        }
      });
    } catch (e) {
      this.$router.push({ name: "invoice" });
    } finally {
      this.loading = false;
    }
  }

  copyNotification(): void {
    NotificationFactory.success("Ссылка скопирована в буфер обмена");
  }
}
